<template>
  <div id="app" class="min-h-screen pt-16 pb-4">
    <loader rounded="" v-if="!$store.state.user.loggedIn && loading"/>

    <template v-if="$store.state.user.loggedIn">
      <div class="fixed top-0 z-50 w-full h-16 bg-white border-b border-gray-200 border-opacity-25 shadow-lg dark:bg-gray-900 dark:border-gray-800">
        <div class="container flex items-center h-full max-w-4xl px-4 mx-auto">
          <div class="flex items-center w-1/6 h-full">
            <button @click="$router.push('/home')" class="flex items-center justify-center py-1 pl-2 pr-3 text-sm text-gray-600 uppercase rounded-full dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:text-gray-200 focus:outline-none"  v-if="homeButton">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
              Apps
            </button>
            <button @click="$route.path.includes('/recipes/edit') ? $router.push('/recipes') : $route.path.includes('/recipes/') ? $router.push('/recipes') : $router.go(-1)" class="flex items-center justify-center py-1 pl-2 pr-3 text-sm text-gray-400 uppercase rounded-full hover:bg-gray-800 hover:text-gray-200 focus:outline-none"  v-if="!homeButton && $route.path != '/home'">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
              Zurück
            </button>
          </div>
          <div class="flex items-center justify-center w-2/3 h-full text-lg font-semibold leading-tight text-center">
            {{title}}
          </div>
          <div class="flex items-center justify-end w-1/6 h-full ml-auto">
            <img :src="$store.getters['user/data'].img" class="w-10 h-10 rounded-full">
          </div>
        </div>
      </div>
    </template>

    <transition :enter-class="routeAnimation ? 'transform translate-y-1/2 opacity-0' : ''" enter-active-class="transition-all duration-200" appear mode="out-in">
      <router-view />
    </transition>

  </div>
</template>

<script>
import loader from '@/components/loader'
import firebase from 'firebase'

export default {
  components: { loader },
  data: () => ({
    // menu: false,
    loading: true,
    routeAnimation: true,
  }),
  computed: {
    title: vm =>
      vm.$route.path == '/' ? 'Hi.' :
      vm.$route.path == '/shopping' ? 'Einkaufsliste' :
      vm.$route.path == '/storage' ? 'Vorräte' :
      vm.$route.path == '/recipes' ? 'Rezeptbuch' :
      vm.$route.path == '/recipes/new' ? 'Neues Rezept' :
      vm.$route.path.includes('/recipes/edit') ? 'Rezept bearbeiten' :
      vm.$route.path.includes('/recipes/') ? vm.$store.getters['recipes/recipeTitle'](vm.$route.path.split('/')[2]) :
      vm.$route.path == '/bills' ? 'Haushaltsbuch' :
      `Hi ${vm.$store.getters['user/data'].displayName.split(' ')[0]}.`,
    homeButton: vm =>
      vm.$route.path == '/home' ? false :
      vm.$route.path.includes('/recipes/') ? false : true,
  },
  watch: {
    '$route'(to, from) {
      // this.menu = false
      to.path == '/' ? this.routeAnimation = true :
      to.path == '/shopping' ? this.routeAnimation = true :
      to.path == '/storage' ? this.routeAnimation = true :
      to.path == '/recipes' ? this.routeAnimation = true :
      to.path == '/recipes/new' ? this.routeAnimation = true :
      to.path.includes('/recipes/edit') ? this.routeAnimation = true :
      to.path.includes('/recipes/') ? this.routeAnimation = false :
      to.path == '/bills' ? this.routeAnimation = true :
      this.routeAnimation = true
    }
  },
  async created() {
    await this.$store.dispatch('shoppinglist/init')
    await this.$store.dispatch('recipes/init')
    await this.$store.dispatch('bills/init')

    firebase.auth().onAuthStateChanged(state => {
      this.loading = state
    })
  }
}
</script>
