import { firestoreAction } from 'vuexfire'
import firebase from 'firebase'
import { db } from '@/main'

export default {
  namespaced: true,
  state: {
    view: true,
    upload: 0,
    all: [],
    categories: []
  },
  mutations: {
    setView: (state, view) => state.view = view,
    setUpload: (state, progress) => state.upload = progress,
    // selectList: (state, id) => state.currentList = id,
    // updateItemTitle: (state, payload) => state.items.filter(item => item.id === payload.id)[0].title = payload.title,
  },
  actions: {
    init: firestoreAction(async ctx => {
      await ctx.bindFirestoreRef('all', db.collection('recipes'))
      await ctx.bindFirestoreRef('categories', db.collection('recipecategories'))
      return
    }),
    addRecipe: firestoreAction(async (ctx, payload) => {
      const ts = (new Date()).getTime()
      const { id } = await db.collection('recipes').add({...payload.data, createdAt: ts})
      if(payload.image) {
        const imgFilename = payload.image.name
        const imgExt = imgFilename.slice(imgFilename.lastIndexOf('.'))
        const imgRef = firebase.storage().ref(`recipes/${id}${imgExt}`)
        let task = imgRef.put(payload.image)
        task.on('state_changed', snapshot => {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          ctx.commit('setUpload', progress)
        }, e => {
          throw e
        }, () => {
          task.snapshot.ref.getDownloadURL().then(imgUrl => {
            console.log('img url:', imgUrl)
            db.collection('recipes').doc(id).update({ imageUrl: imgUrl }).then(() => { return })
            // return id
            return
          })
        })
      }
      ctx.commit('setUpload', 100)
      return id
    }),
    updateRecipe: firestoreAction(async (ctx, payload) => {
      db.collection('recipes').doc(payload.id).update({
        title: payload.title,
        time: payload.time,
        level: payload.level,
        ingredients: payload.ingredients,
        preparation: payload.preparation,
        instructions: payload.instructions,
        categories: payload.categories
      })
      if(payload.image) {
        const oldImgRef = firebase.storage().ref(`recipes/${payload.id}.jpg`)
        oldImgRef.delete().then(() => {
          const imgFilename = payload.image.name
          const imgExt = imgFilename.slice(imgFilename.lastIndexOf('.'))
          const imgRef = firebase.storage().ref(`recipes/${payload.id}${imgExt}`)
          let task = imgRef.put(payload.image)
          task.on('state_changed', snapshot => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            ctx.commit('setUpload', progress)
          }, e => {
            throw e
          }, () => {
            task.snapshot.ref.getDownloadURL().then(imgUrl => {
              db.collection('recipes').doc(payload.id).update({ imageUrl: imgUrl }).then(() => { return })
              return
            })
          })
        }).catch(() => {
          const imgFilename = payload.image.name
          const imgExt = imgFilename.slice(imgFilename.lastIndexOf('.'))
          const imgRef = firebase.storage().ref(`recipes/${payload.id}${imgExt}`)
          let task = imgRef.put(payload.image)
          task.on('state_changed', snapshot => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            ctx.commit('setUpload', progress)
          }, e => {
            throw e
          }, () => {
            task.snapshot.ref.getDownloadURL().then(imgUrl => {
              db.collection('recipes').doc(payload.id).update({ imageUrl: imgUrl }).then(() => { return })
              return
            })
          })
        })
      }
      ctx.commit('setUpload', 100)
      return  
    }),
    delete: firestoreAction(async (ctx, id) => {
      db.collection('recipes').doc(id).delete().then(() => {
        firebase.storage().ref(`recipes/${id}.jpg`).delete().then(() => { return }).catch(() => { return })
      })
    }),
    addCategory: firestoreAction(async (ctx, title) => {
      return db.collection('recipecategories').add({ title: title })
    })
  },
  getters: {
    view: state => state.view,
    upload: state => state.upload,
    all: state => state.all.sort((a, b) => b.createdAt - a.createdAt),
    categories: state => state.categories.sort((a, b) => b.title - a.title),
    recipeTitle: state => id => state.all.filter(recipe => recipe.id == id)[0].title,
    single: state => id => state.all.filter(recipe => recipe.id == id)[0],
    latest: state => state.all[0]
  }
}