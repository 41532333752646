export default {
  namespaced: true,
  state: {
    loggedIn: false,
    data: null
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.loggedIn = value;
    },
    SET_USER(state, data) {
      state.data = data;
    }
  },
  actions: {
    init({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        // console.log(user)
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          img: user.photoURL
        });
      } else {
        commit("SET_USER", null);
      }
    }
  },
  getters: {
    data: state => state.data
  },
}