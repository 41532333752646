import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import user from './user'
import shoppinglist from './shoppinglist'
import recipes from './recipes'
import bills from './bills'

Vue.use(Vuex)

export default new Vuex.Store({
  mutations: {
    ...vuexfireMutations
  },
  modules: {
    user,
    shoppinglist,
    recipes,
    bills
  }
})
