import Vue from 'vue'
import App from './App.vue'
import Toast, { POSITION } from 'vue-toastification'
import './registerServiceWorker'
import router from './router'
import store from './store'
import firebase from 'firebase'

import 'vue-toastification/dist/index.css'
import '@/assets/main.css'

Vue.use(Toast, {
  timeout: 2000,
  position: POSITION.BOTTOM_CENTER,
  transition: 'Vue-Toastification__slideBlurred',
  toastClassName: 'toast-primary',
})

firebase.initializeApp({
  apiKey: 'AIzaSyAA32haYOpKbrMmWiUcV-G-efnNUu4VcIo',
  authDomain: 'home-c9bc0.firebaseapp.com',
  databaseURL: 'https://home-c9bc0.firebaseio.com',
  projectId: 'home-c9bc0',
  storageBucket: 'home-c9bc0.appspot.com',
  messagingSenderId: '886564745807',
  appId: '1:886564745807:web:6df474c42903729fc4f03a',
  measurementId: 'G-1MKY97JJ07'
})
export const db = firebase.firestore()

firebase.auth().onAuthStateChanged(user => {
  if(user) {
    store.dispatch('user/init', user)
    if(user && router.currentRoute.path == '/') router.replace('/home')
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) },
}).$mount('#app')
