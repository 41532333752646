import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/shopping',
    component: () => import('../views/Shoppinglist.vue')
  },
  {
    path: '/storage',
    component: () => import('../views/Storage.vue')
  },
  {
    path: '/recipes',
    component: () => import('../views/Recipes.vue')
  },
  {
    path: '/recipes/new',
    component: () => import('../views/RecipeNew.vue')
  },
  {
    path: '/recipes/edit/:id',
    component: () => import('../views/RecipeNew.vue')
  },
  {
    path: '/recipes/:id',
    component: () => import('../views/Recipe.vue')
  },
  {
    path: '/bills',
    component: () => import('../views/Bills.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const auth = store.state.user.loggedIn
  // console.log('navigating to', to.path)
  // console.log('auth state', auth)
  if (to.path !== '/' && !auth) next('/')
  else if(to.path == '/' && auth) next('/shopping')
  else next()
})

export default router
