import { firestoreAction } from 'vuexfire'
import { db } from '@/main'

export default {
  namespaced: true,
  state: {
    records: []
  },
  mutations: {
    // selectPlace: (state, id) => state.currentPlace = id,
  },
  actions: {
    init: firestoreAction(async ctx => {
      await ctx.bindFirestoreRef('records', db.collection('bills'))
      // await ctx.bindFirestoreRef('items', db.collection('storageitems'))
      return
    }),
    addRecord: firestoreAction(async (ctx, payload) => {
      const ts = (new Date()).getTime()
      const user = ctx.rootGetters['user/data']
      let record = {
        user: user,
        createdAt: ts,
        ...payload
      }
      record.value = record.value.replace(',', '.')
      const { id } = await db.collection('bills').add(record)
      return id
    }),
    delete: firestoreAction(async (ctx, id) => {
      return db.collection('bills').doc(id).delete()
    }),
    update: firestoreAction(async (ctx, payload) => {
      return db.collection('bills').doc(payload.id).update(payload.data)
    }),
  },
  getters: {
    all: state => state.records,
    single: state => id => state.records.find(record => record.id == id),
    total: state => state.records.reduce((acc, cur) => cur.income ? acc + Number(cur.value) : acc - Number(cur.value), 0)
  }
}