import { firestoreAction } from 'vuexfire'
import { db } from '@/main'

export default {
  namespaced: true,
  state: {
    lists: [],
    currentList: 1,
    items: []
  },
  mutations: {
    selectList: (state, id) => state.currentList = id,
  },
  actions: {
    init: firestoreAction(async ctx => {
      await ctx.bindFirestoreRef('lists', db.collection('shoppinglists'))
      await ctx.bindFirestoreRef('items', db.collection('shoppingitems'))
      return
    }),
    addList: firestoreAction(async (ctx, payload) => {
      const res = await db.collection('shoppinglists').add({ title: payload })
      ctx.commit('selectList', res.id)
      return
    }),
    deleteList: firestoreAction(async ctx => {
      const listID = ctx.getters['currentList']
      const items = ctx.getters['items']
      items.forEach(async item => {
        await db.collection('shoppingitems').doc(item.id).delete()
      })
      db.collection('shoppinglists').doc(listID).delete()
      ctx.commit('selectList', 1)
      return
    }),
    addItem: firestoreAction(async (ctx, payload) => {
      const listID = ctx.getters['currentList']
      const itemsCount = ctx.getters['itemsInList'](listID)
      let item = {
        title: payload,
        list: ctx.state.currentList,
        index: itemsCount,
        bought: false
      }
      return db.collection('shoppingitems').add(item)
    }),
    addItemGrocery: firestoreAction(async (ctx, payload) => {
      const listID = 1
      const itemsCount = ctx.getters['itemsInList'](listID)
      let item = {
        title: payload,
        list: listID,
        index: itemsCount,
        bought: false
      }
      return db.collection('shoppingitems').add(item)
    }),
    checkItem: firestoreAction(async (ctx, payload) => {
      return db.collection('shoppingitems').doc(payload.item).update({ bought: !payload.old })
    }),
    updateItemPosition: firestoreAction(async (ctx, payload) => {
      return db.collection('shoppingitems').doc(payload.item).update({ index: payload.index })
    }),
    updateItemTitle: firestoreAction(async (ctx, payload) => {
      return db.collection('shoppingitems').doc(payload.id).update({ title: payload.title })
    }),
    deleteItem: firestoreAction(async (ctx, id) => {
      return db.collection('shoppingitems').doc(id).delete()
    })
  },
  getters: {
    lists: state => state.lists,
    currentList: state => state.currentList,
    getCurrentList: state => state.lists.filter(list => list.id == state.currentList)[0],
    items: state => state.items.filter(item => item.list == state.currentList),
    item: state => id => state.items.filter(item => item.id === id)[0],
    itemsInList: state => list => state.items.filter(item => item.list == list).length
  }
}