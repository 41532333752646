<template>
  <div :class="['inset-0 w-full h-full flex items-center justify-center', zIndex, contained ? 'absolute' : 'fixed']">
    <div :class="['absolute inset-0 w-full h-full', rounded, bg]" />
    <svg class="z-10 w-20 h-20 ml-10 text-gray-600 opacity-75 dark:text-white" version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
      <circle fill="currentColor" stroke="none" cx="6" cy="50" r="6">
        <animate attributeName="opacity" dur="1.5s" values="0;1;0" repeatCount="indefinite" begin="0.1"/>    
      </circle>
      <circle fill="currentColor" stroke="none" cx="26" cy="50" r="6">
        <animate attributeName="opacity" dur="1.5s" values="0;1;0" repeatCount="indefinite" begin="0.25"/>       
      </circle>
      <circle fill="currentColor" stroke="none" cx="46" cy="50" r="6">
        <animate attributeName="opacity" dur="1.5s" values="0;1;0" repeatCount="indefinite" begin="0.4"/>     
      </circle>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    contained: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: String,
      default: 'rounded-md'
    },
    bg: {
      type: String,
      default: 'bg-gray-800 opacity-75'
    },
    zIndex: {
      type: String,
      default: 'z-30'
    }
  }
}
</script>
